<template>
  <ion-page class="page scrollable bg-transparent">
    <div class="d-flex justify-content-between mt-3">
      <div class="title">
        Posts by

        <router-link :to="{ name: 'profile', params: { username } }" class="clickable-item-hov">{{
          username
        }}</router-link>
      </div>

      <div v-if="isOwner" class="d-flex align-items-center mb-2">
        <ion-button class="button" @click="createPost"> Create Post </ion-button>
      </div>
    </div>

    <!-- <blabs-tabs class="my-2" :tabs="tabs" :active-index="tabIndex" @tab-changed="tabChanged" /> -->
    <char-posts-tabs
      class="mb-2"
      :btn-clr="btnColor"
      :text-clr="textClr"
      :tabs="tabs"
      :count="blabsCount"
      :active-index="tabIndex"
      :text-btn-clr="btnCheckedTextClr"
      @tab-changed="tabChanged"
    />
    <all-posts :posts="userPosts" :total-posts="totalPosts" @loadMore="loadMore" />
    <!-- <infinite-posts
      class="my-2"
      :current-tab="currentTab"
      :post="userPosts"
      :featured="featuredPosts"
      :paging="paging"
      :featured-paging="featuredpaging"
      @load="requestLoadMore"
      @loadmore="requestLoadMoreFeatured"
    /> -->
  </ion-page>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Action, Getter } from 's-vuex-class';
import BlabsTabs from './components/UserBlabsTabs.vue';
import AllPosts from '@/shared/components/Posts/AllPosts.vue';
import { usersBlabsTabs } from '@/shared/statics/tabs';
import namespace from '@/shared/store/namespace';
import { Paging, Tab, User } from '@/shared/types/static-types';
import constants from '@/shared/statics/constants';
import CharPostsTabs from '@/shared/pages/blabs/components/CharPostsTabs.vue';
import { getFeaturedUserPosts, getNextPage, getPosts, getPostsCount } from '@/shared/actions/blabs';
import { authStore } from '@/shared/pinia-store/auth';

import { alertController } from '@ionic/vue';

@Options({
  name: 'UserBlabs',
  components: {
    BlabsTabs,
    // InfinitePosts,
    AllPosts,
    CharPostsTabs,
  },
})
export default class UserBlabs extends Vue {
  public tabs = usersBlabsTabs;
  public currentTab: string = 'blabs';
  public tabIndex = 0;
  public pageNum = 1;
  public blabsCount = {};
  public totalPosts = 0;
  public user: User | null = null;
  public paging: Paging | null = null;
  public featuredpaging: Paging | null = null;
  userPosts = [];
  featuredPosts = [];

  public get isAuthenticated() {
    const { isAuthenticated } = authStore();
    return isAuthenticated.value;
  }

  public createPost() {
    const router = useRouter();
    const href = router.resolve({ name: 'create-post' }).href;
    location.assign(href);
  }

  @Getter('byUsername', { namespace: namespace.UsersModule })
  findUserByUsername!: (username: string) => User;

  @Action('getUserByUsername', { namespace: namespace.UsersModule })
  public getUserByUsername!: any;

  public tabChanged({ value }: Tab) {
    const router = useRouter();
    this.currentTab = value;
    router.replace({ name: 'user-posts', query: { tab: value } });
    this.userPosts = [];
    this.pageNum = 1;
    this.userBlabs(this.currVal);
  }

  public get username() {
    const router = useRouter();
    return router.currentRoute.value.params.username;
  }

  public get loggedInUser() {
    const { user } = authStore();
    return user.value;
  }

  public async fetchPostsCount() {
    const resp = await getPostsCount({ user: this.user!.id });
    this.blabsCount = resp;
  }

  public async userBlabs(value: any) {
    let resp: any;
    if (isEmpty(value)) {
      resp = await getPosts(this.pageNum, constants.defaultPageSize, {
        user: this.user!.id,
        ordering: '-created',
      });
    } else {
      resp = await getPosts(this.pageNum, constants.defaultPageSize, {
        user: this.user!.id,
        category: value,
        ordering: '-created',
      });
    }
    this.userPosts = this.userPosts.concat(resp.results);
    this.totalPosts = resp.count;
  }

  public get currVal() {
    const value = this.currentTab === 'blabs' ? '' : this.currentTab;
    return value;
  }

  public loadMore = () => {
    this.pageNum++;

    this.userBlabs(this.currVal);
  };

  public get btnColor() {
    return '#b140e6';
  }

  public get textClr() {
    return '#214163';
  }
  public get btnCheckedTextClr() {
    return '#fff';
  }

  public get isOwner() {
    return this.user?.id === this.loggedInUser.id;
  }

  public nsfwWarning = async () => {
    if (this.user) {
      if (!this.isAuthenticated && (this.user.is_nsfw || this.user.show_nsfw)) {
        const alert = await alertController.create({
          cssClass: 'char-warning-alert',
          header: 'Warning',
          message: `You are about to see potentially mature content, by proceeding you confirm you are 18 years of age or older.`,
          buttons: [
            {
              text: 'Continue',
              id: 'confirm-button',
              role: 'ok',
              cssClass: 'mx-auto',
            },
          ],
          backdropDismiss: false,
        });
        await alert.present();
      }
    }
  };

  public async featuredUserBlabs(page = 1) {
    const { results, ...paging } = await getFeaturedUserPosts(this.user!.id, page);
    this.featuredPosts = results;
    this.featuredpaging = paging;
  }

  public async requestLoadMore() {
    try {
      const { results, ...paging } = await getNextPage(this.paging!);
      this.userPosts = this.userPosts.concat(results);
      this.paging = paging;
    } catch (e) {}
  }

  public async requestLoadMoreFeatured() {
    try {
      const { results, ...paging } = await getNextPage(this.featuredpaging!);
      this.featuredPosts = this.featuredPosts.concat(results);
      this.featuredpaging = paging;
    } catch (e) {}
  }

  public created() {
    const router = useRouter();
    const document = useDocument();
    document.value?.getElementById('app-nav-bar')?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });

    const tIndex = indexOf(
      this.tabs,
      find(this.tabs, (tab) => tab.value === router.currentRoute.value.query.tab)
    );
    if (router.currentRoute.value.query.tab && tIndex !== -1) {
      this.tabIndex = tIndex;
      this.currentTab = router.currentRoute.value.query.tab;
    }
  }

  async mounted() {
    await this.getUserByUsername(this.username);
    this.user = this.findUserByUsername(this.username);

    this.nsfwWarning();

    this.userBlabs(this.currVal);
    this.fetchPostsCount();
    this.featuredUserBlabs();
  }
}
</script>
<style lang="sass" scoped>
.button
  text-transform: none !important
  --border-radius: 6px !important
.title
  color: #214163
  font-size: 24px
  font-weight: bold
  a
    color: #214163
</style>
